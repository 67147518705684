var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":"","scrollable":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.model.id ? 'Edición' : 'Creación')+" Feria ")])]),_c('v-card-text',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 pb-0"},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"name","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 pb-0"},[_c('validation-provider',{attrs:{"name":"Año","vid":"year","rules":"required|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Año","required":""},model:{value:(_vm.model.year),callback:function ($$v) {_vm.$set(_vm.model, "year", $$v)},expression:"model.year"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 py-0"},[_c('validation-provider',{attrs:{"name":"Fecha inicio","vid":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"label":"Fecha de inicio","hide-details":false,"errors":errors},model:{value:(_vm.model.startDate),callback:function ($$v) {_vm.$set(_vm.model, "startDate", $$v)},expression:"model.startDate"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 py-0"},[_c('validation-provider',{attrs:{"name":"Fecha fin","vid":"endDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"label":"Fecha de fin","hide-details":false,"errors":errors},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-0"},[_c('validation-provider',{attrs:{"vid":"description","name":"Descripción","rules":"min:0|max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"600","outlined":"","type":"text","rows":"2","error-messages":errors,"clear-icon":"mdi-close-circle","label":"Escribe una breve descripción de la feria"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 py-0"},[_c('file-chooser',{ref:"fileChooser",attrs:{"options":{
                  height: '190px',
                  description:
                    'Haz clic aquí para cargar el plano general de la feria',
                  regex: /\.(jpg|jpeg|png|gif)$/,
                  error: 'Solo se permiten imagenes',
                  fileSize: 2,
                }},on:{"input":_vm.onLoadPlaneImageUrl}}),_c('small',[_vm._v("El tamaño que debe tener la imagen general: 1200 x 678 px.")])],1),_c('div',{staticClass:"col-12 col-md-6 py-0"},[_c('file-chooser',{ref:"fileChooser2",attrs:{"options":{
                  height: '190px',
                  description:
                    'Haz clic aquí para cargar el pin del plano general de la feria',
                  regex: /\.(jpg|jpeg|png|gif)$/,
                  error: 'Solo se permiten imagenes',
                  fileSize: 2,
                }},on:{"input":_vm.onLoadPinIconUrl}})],1),_c('div',{staticClass:"col-12 py-0 pl-4"},[_c('v-switch',{attrs:{"label":"¿Activa?"},model:{value:(_vm.model.isActive),callback:function ($$v) {_vm.$set(_vm.model, "isActive", $$v)},expression:"model.isActive"}})],1)])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveFair}},[_vm._v(" Aceptar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }